import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"


const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Envío de ofertas y promociones",
      description: "Utiliza datos de comportamiento y preferencias recopilados a través de Instagram y otros canales para enviar ofertas y promociones personalizadas. Incrementa tus tasas de conversión y refuerza la lealtad hacia tu empresa con comunicaciones adaptadas a intereses específicos.",
      image: imageOneMobile,
      background: "#EEEAFB"
    },
    {
      title: "Automatiza respuestas a los DM",
      description: "Agiliza la gestión de tus mensajes con chatbots con IA que respondan mensajes automáticos basados en palabras clave. Asegura que las consultas se respondan rápidamente o se enruten al departamento o agente correcto, mejorando la satisfacción del cliente.",
      image: imageTwoMobile,
      background: "#FCEEEE"
    },
    {
      title: "Enruta tus tickets de atención",
      description: "Enruta al agente o equipo adecuado cualquier consulta o problema que se genere por chat de Instagram. Asegura que los problemas se resuelvan rápidamente y mejora la experiencia general del cliente.",
      image: imageFourMobile,
      background: "#EEEAFB"
    },
    {
      title: "Mensajes interactivos",
      description: "Crea experiencias únicas que promuevan la participación del usuario con mensajes con botones de respuesta. Guía a los usuarios a través de una variedad de flujos de interacción predeterminados, como hacer una compra, reservar, o recibir soporte.",
      image: imageThreeMobile,
      background: "#FCEEEE"
    },
  ]

  return (
    <section className="container-experience-instagram">
      <p className="container-experience-instagram-title">
        Te ayudamos a crear experiencias conversacionales que te ayuden a tener <TitleUnderlineOne underline="más conversiones" />
      </p>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-instagram-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-instagram-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }}>
            <p className="container-experience-instagram-cardsOne-card-title">Envío de ofertas y promociones</p>
            <p className="container-experience-instagram-cardsOne-card-description">
              Utiliza datos de comportamiento y preferencias recopilados a través de Instagram y otros canales para enviar ofertas y promociones personalizadas. Incrementa tus tasas de conversión y refuerza la lealtad hacia tu empresa con comunicaciones adaptadas a intereses específicos.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-instagram-cardsOne-card-image"
              src={imageOneDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-instagram-cardsOne-column">
          {/* columna */}
          <section className="container-experience-instagram-cardsOne-card" style={{ background: "#FCEEEE", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-instagram-cardsOne-card-title">Automatiza respuestas a los DM</p>
              <p className="container-experience-instagram-cardsOne-card-description">
                Agiliza la gestión de tus mensajes con chatbots con IA que respondan mensajes automáticos basados en palabras clave. Asegura que las consultas se respondan rápidamente o se enruten al departamento o agente correcto, mejorando la satisfacción del cliente.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-instagram-cardsOne-card-image"
                src={imageTwoDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-instagram-cardsTwo">
        {/* una sola fila */}
        <section className="container-experience-instagram-cardsTwo-card" style={{ background: "#FCEEEE", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-instagram-cardsTwo-card-title">Mensajes interactivos</p>
            <p className="container-experience-instagram-cardsTwo-card-description">
              Crea experiencias únicas que promuevan la participación del usuario con mensajes con botones de respuesta. Guía a los usuarios a través de una variedad de flujos de interacción predeterminados, como hacer una compra, reservar, o recibir soporte.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-instagram-cardsTwo-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-instagram-cardsTwo-column">
          {/* columna */}
          <section className="container-experience-instagram-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-instagram-cardsTwo-card-title">Enruta tus tickets de atención</p>
              <p className="container-experience-instagram-cardsTwo-card-description">
                Enruta al agente o equipo adecuado cualquier consulta o problema que se genere por chat de Instagram. Asegura que los problemas se resuelvan rápidamente y mejora la experiencia general del cliente.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-instagram-cardsTwo-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>


      <div className="container-experience-instagram-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-instagram-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-instagram-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-instagram-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                    <br />
                  </section>
                  <section>
                    <img
                      className="container-experience-instagram-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
